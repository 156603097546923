import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import EL from 'element-plus';
import zh_CN from 'element-plus/dist/locale/zh-cn.mjs' // 中文
import 'element-plus/dist/index.css'
import './assets/public.css'

const app = createApp(App)
app.use(EL, { locale: zh_CN }).use(router).mount('#app')
